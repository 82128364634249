var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height of the product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width of the product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#colour-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "colour-filter"
            }
          },
          [_vm._v(" Colour ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "colour-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth of the product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "connectivity-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#accessory-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Included accessories ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "accessory-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.accessories,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select accessories",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.accessoryChanged },
              model: {
                value: _vm.accessory,
                callback: function($$v) {
                  _vm.accessory = $$v
                },
                expression: "accessory"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type of control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "control-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.controlTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select control types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.controlTypeChanged },
              model: {
                value: _vm.controlType,
                callback: function($$v) {
                  _vm.controlType = $$v
                },
                expression: "controlType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#noise-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "noise-filter"
            }
          },
          [_vm._v(" Noise ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "noise-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.noiseLevels,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select noises",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.noiseChanged },
              model: {
                value: _vm.noise,
                callback: function($$v) {
                  _vm.noise = $$v
                },
                expression: "noise"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#efficiency-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "efficiency-filter"
            }
          },
          [_vm._v(" Energy Efficiency ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "efficiency-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyEfficiencies,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy efficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyEfficiencyChanged },
              model: {
                value: _vm.energyEfficiency,
                callback: function($$v) {
                  _vm.energyEfficiency = $$v
                },
                expression: "energyEfficiency"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }